import { cn } from '@hapstack/common'
import { H3, StyledIcon } from '@hapstack/ui'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { type ComponentProps, createContext, useContext } from 'react'

const emptyStyles = cva(
  'w-full border border-dashed rounded-lg p-4 border-border',
  {
    variants: {
      orientation: {
        horizontal: 'flex items-center gap-4',
        vertical: 'flex flex-col items-center justify-center gap-4 py-6',
      },
    },
    defaultVariants: {
      orientation: 'horizontal',
    },
  }
)

type EmptyStyles = VariantProps<typeof emptyStyles>

type EmptyProps = EmptyStyles & ComponentProps<'div'>

const EmptyContext = createContext<EmptyStyles>({ orientation: 'horizontal' })

const useEmptyContext = () => {
  const context = useContext(EmptyContext)
  if (!context) throw new Error('EmptyContext not found')
  return context
}

const Empty = ({
  orientation = 'horizontal',
  className,
  ...props
}: EmptyProps) => (
  <EmptyContext.Provider value={{ orientation }}>
    <div
      className={cn(emptyStyles({ orientation }), className)}
      {...props}
    />
  </EmptyContext.Provider>
)

const EmptyIcon = (props: ComponentProps<typeof StyledIcon>) => {
  const { orientation } = useEmptyContext()
  return (
    <StyledIcon
      size={orientation === 'horizontal' ? 'md' : 'xl'}
      {...props}
    />
  )
}

const EmptyHeader = ({
  children,
  className,
  ...props
}: ComponentProps<'div'>) => {
  const { orientation } = useEmptyContext()
  return (
    <div
      className={cn(
        orientation === 'vertical'
          ? 'max-w-md space-y-1 text-center'
          : 'space-y-1',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

const EmptyTitle = ({
  children,
  className,
  ...props
}: ComponentProps<'div'>) => {
  const { orientation } = useEmptyContext()
  return (
    <H3
      as="div"
      className={cn(
        'font-medium',
        orientation === 'horizontal' ? 'text-base leading-none' : 'text-xl',
        className
      )}
      {...props}
    >
      {children}
    </H3>
  )
}

const EmptyDescription = ({
  children,
  className,
  ...props
}: ComponentProps<'p'>) => {
  const { orientation } = useEmptyContext()
  return (
    <p
      className={cn(
        orientation === 'horizontal'
          ? 'text-pretty text-sm'
          : 'text-balance text-md',
        className
      )}
      {...props}
    >
      {children}
    </p>
  )
}

const EmptyAction = ({ className, ...props }: ComponentProps<'div'>) => {
  const { orientation } = useEmptyContext()

  return (
    <div
      className={cn(orientation === 'horizontal' && 'ml-auto', className)}
      {...props}
    />
  )
}
export {
  Empty,
  EmptyAction,
  EmptyDescription,
  EmptyHeader,
  EmptyIcon,
  EmptyTitle,
}
